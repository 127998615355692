<template>
  <div class="techno">
    <img :src="data.url + 'techno/bg_1.png'" alt="" class="bg1" />
    <img :src="data.url + 'techno/bg_2.png'" alt="" class="bg2" />
    <div class="content">
      <div class="title">以科技赋能<br />让每个人活出健康与美丽</div>
      <div class="total">
        <img :src="data.url + 'techno/total_1.png'" alt="" />
        <img :src="data.url + 'techno/total_2.png'" alt="" />
        <img :src="data.url + 'techno/total_3.png'" alt="" />
      </div>
      <div class="numTotal">
        <div class="totalItem">
          <div class="num">99张</div>
          <div class="text">科研海报</div>
        </div>
        <div class="line"></div>
        <div class="totalItem">
          <div class="num">134座</div>
          <div class="text">产品奖项</div>
        </div>
        <div class="line"></div>
        <div class="totalItem">
          <div class="num">73项</div>
          <div class="text">设计和发明专利</div>
        </div>
        <div class="line"></div>
        <div class="totalItem">
          <div class="num">40篇</div>
          <div class="text">权威学术论文</div>
        </div>
      </div>
      <div class="mainText">
        ……<br />
        用创新和个性化<br />
        引领 NU SKIN 迈向美好未来
      </div>
      <div class="totalText">*包含全球范围内已获取设计和发明专利</div>
      <div class="totalText" style="margin-top: 0">*数据统计截至2023年底</div>
      <div class="mortIcon">
        <img :src="data.url + 'more.png'" alt="" />
      </div>
      <div class="tab">
        <img
        v-if="data.tab!=2"
        :src="data.url+'techno/tabBg1.png'" 
        :class="data.tab==1?'tabBg1':'tabBg2'">
        <img
          v-if="data.tab==2"
          :src="data.url+'techno/tabBg2.png'" 
          class="tabBg3">
        <div 
          :class="{'tabItem':true,'selected':data.tab==1}"
          @click="changeTab(1)">
          数说如新<br/>科研40年
        </div>
        <div 
          :class="{'tabItem':true,'selected':data.tab==2}"
          @click="changeTab(2)">
          NU SKIN <br/>生产基地
        </div>
        <div 
          :class="{'tabItem':true,'selected':data.tab==3}"
          @click="changeTab(3)">
          热爱如新<br/> 美丽如新
        </div>
      </div>
      <div class="video">
        <video
          ref="myVideo"
          width="100%"
          style="object-fit: contain"
          preload
          controls="controls"
          :poster="data.poster"
          :src="data.videoUrl"
        ></video>
      </div>
      <div class="part1">
        <img :src="data.url + 'techno/bg_3.png'" alt="" class="bg3" />
        <div class="downLoadBtn" @click="lookVideo">
          分享和下载视频
          <img :src="data.url + 'right.png'" alt="" />
        </div>
        <div class="lunbo">
          <div
            v-if="data.activeIndex == 0"
            class="firstImg swiper-slide swiper-slide-prev"
          >
            <img
              src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/6.png"
              alt=""
            />
          </div>
          <swiper
            ref="mySwiper"
            :slidesPerView="'auto'"
            :centeredSlides="true"
            :spaceBetween="0"
            :slideToClickedSlide="true"
            @activeIndexChange="onSlideChange"
            @swiper="setSwiperRef"
          >
            <template v-for="(item, index) in data.imgList" :key="index">
              <SwiperSlide>
                <img :src="item" alt="" />
              </SwiperSlide>
            </template>
          </swiper>
          <div
            v-if="data.activeIndex == data.imgList.length - 1"
            class="lastImg swiper-slide swiper-slide-next"
          >
            <img
              src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/1.jpg"
              alt=""
            />
          </div>
        </div>
        <div class="pagination">
          <template v-for="(item, index) in data.imgList" :key="index">
            <span :class="data.activeIndex == index ? 'active' : ''"></span>
          </template>
        </div>
        <div class="partTitle" @click="morePoster">
          获取并下载更多海报
          <img :src="data.url + 'right.png'" alt="" />
        </div>
        <div class="mortIcon" style="margin-top: 0.32rem">
          <img :src="data.url + 'more.png'" alt="" />
        </div>
        <!-- <div class="box">
          <div class="light"></div>
          <div class="boxTitle">是什么造就了如新的科研领先？</div>
          <div class="boxContent">
            <div class="boxCol">
              整全理念和大胆创新
              <img :src="data.url + 'right_blue.png'" alt="" />
            </div>
            <div class="line"></div>
            <div class="boxCol">
              产品质量和研发理念
              <img :src="data.url + 'right_blue.png'" alt="" />
            </div>
          </div>
        </div> -->
      </div>
      <div class="part2">
        <img :src="data.url + 'techno/bg_4.png'" alt="" class="bg4" />
        <!-- <van-swipe :autoplay="3000" indicator-color="white">
          <template v-for="(item, index) in data.imgList" :key="index">
            <van-swipe-item>
              <img :src="item" alt="" />
            </van-swipe-item>
          </template>
        </van-swipe> -->
        <div class="text_info">40年创新实力</div>
        <div class="text_title">我们用数据说话</div>
        <div class="downLoadBtn" @click="openPdf">
          查阅下载完整版《如新科研故事》
          <img :src="data.url + 'brandStory/file.png'" alt="" />
        </div>
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { reactive, onMounted } from "vue";
import { baiduTotal } from "../js/common.js";
import { prviewVideoBox } from "../js/until";
import { Swiper, SwiperSlide } from "swiper/swiper-vue.mjs";
let mySwiper = null;
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
  imgList: [
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/1.jpg",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/2.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/3.jpg",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/4.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/5.png",
    "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/6.png",
  ],
  activeIndex: 0, //当前图片下标
  tab:1,//tab下标
  videoUrl:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/techo.mp4',//视频链接
  poster:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/techoCover.png',
  videoObj:{
    1:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/techo.mp4',
    2:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/jidi.mp4',
    3:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/loveruxin.mp4'
  },
  posterObj:{
    1:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/techoCover.png',
    2:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/jidiCover.png',
    3:'https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/loveCover.png'
  },//封面图
});
const router = useRouter();
let morePoster = () => {
  window.location.href =
    "https://nudrive.cn.nuskin.com/file/hqwhe10i8065kcy6m9pfe6st2o6jbrrt";
};
//切换下标
let changeTab=(tab)=>{
  data.tab=tab;
  data.videoUrl=data.videoObj[tab];
  data.poster=data.posterObj[data.tab];
}
//打开pdf
let openPdf = () => {
  window.location.href =
    "https://nudrive.cn.nuskin.com/file/xylqimib3jvnlna9bucsbigezuz2ctme#";
};
//返回首页
let backIndex = () => {
  router.replace("/index");
};
//轮播下标
let onSlideChange = (e) => {
  data.activeIndex = e.activeIndex;
};
//自动播放
let setSwiperRef = (swiper) => {
  mySwiper.value = swiper;
  autoplay();
};
//自动播放
let autoplay = () => {
  setInterval(() => {
    if (data.activeIndex == 5) {
      mySwiper.value.slideTo(0, 1);
    } else {
      mySwiper.value.slideNext();
    }
  }, 5000);
};
//查看视频
let lookVideo = () => {
  let qcodeUrl='https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/techoQR.png';
  let wangpanPath="https://nudrive.cn.nuskin.com/file/j3ltdstz8fd32yxhca4b89ueac29nu0f#";
  if(data.tab==2){
    qcodeUrl='https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/jidiQR.png';
    wangpanPath='https://nudrive.cn.nuskin.com/file/vksqkgk4ozbj3vyoog91l4wbfscwgrvb?path=%E7%A7%91%E7%A0%94%E6%95%85%E4%BA%8B%2FNU%20SKIN%20%E7%94%9F%E4%BA%A7%E5%9F%BA%E5%9C%B0.mp4&_=1715055641092';
  }
  if(data.tab==3){
    qcodeUrl='https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/techno/loveQr.png';
    wangpanPath='https://nudrive.cn.nuskin.com/file/w9639jah762clltjc9to30cgumo8u3se#';
  }
  prviewVideoBox({
    videoUrl: data.videoUrl,
    qcodeUrl: qcodeUrl,
    poster:data.poster,
    wangpanPath:wangpanPath,
  });
};
onMounted(() => {
  baiduTotal();
});
</script>
<style lang="scss" scoped>
.techno {
  width: 100%;
  height: 100%;
  position: relative;
  background: #4b6794;
  box-sizing: border-box;
  overflow-y: auto;
  .content {
    position: relative;
    width: 100%;
    z-index: 2;
    padding-top: 1.3rem;
    .title {
      color: #fff;
      text-align: center;
      font-family: Source Han Serif SC;
      font-size: 0.26rem;
      font-style: normal;
      font-weight: 600;
      line-height: 0.4rem; /* 153.846% */
    }
    .total {
      width: 100%;
      padding: 0 0.1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;
      img {
        width: 1.12rem;
        height: 1.12rem;
      }
    }
    .numTotal {
      width: 100%;
      padding: 0.1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;
      .totalItem {
        .num {
          color: #fff;
          text-align: center;
          font-family: PingFang SC;
          font-size: 0.18rem;
          font-weight: 600;
        }
        .text {
          color: rgba(255, 255, 255, 0.9);
          text-align: center;
          font-family: PingFang SC;
          font-size: 0.12rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.16rem;
        }
      }
      .line {
        width: 1px;
        height: 0.45rem;
        background: #fff;
      }
    }
    .mainText {
      margin-top: 0.16rem;
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 0.32rem; /* 200% */
      letter-spacing: 1.28px;
    }
    .totalText {
      margin-top: 0.2rem;
      color: #fff;
      text-align: center;
      font-family: PingFang SC;
      font-size: 0.12rem;
      font-weight: 400;
      letter-spacing: 0.77px;
    }
    .mortIcon {
      position: relative;
      margin: 0.2rem 0;
      text-align: center;
      font-size: 0;
      img {
        width: 0.18rem;
      }
    }
    .tab{
      position: relative;
      width: 3.5rem;
      height: 0.48rem;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba($color: #fff, $alpha: 0.3);
      border-radius: 0.2rem;
      background:radial-gradient(
              at top center,
              rgba(197, 234, 255, 0.2),
              rgba(197, 234, 255, 0),
              rgba(197, 234, 255, 0)
            ),
      linear-gradient(
          98deg,
          rgba(122, 189, 228, 0),
          rgba(122, 189, 228, 0.4)
        ),#4C6895;
      box-shadow: 0 4px 8px 0 rgba(187,212,221,0.6);
      .tabItem{
        flex: 1;
        text-align: center;
        font-size: 0.14rem;
        color: #fff;
        font-weight: bold;
        z-index: 2;
      }
      .selected{
        color: #4C6895;
      }
      .tabBg1{
        position: absolute;
        bottom: 0;
        left: -2px;
        height: 0.52rem;
        z-index: 1;
        transform: scaleX(-1);
      }
      .tabBg2{
        position: absolute;
        bottom: 0;
        right: -2px;
        height: 0.52rem;
        z-index: 1;
      }
      .tabBg3{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 0.52rem;
        z-index: 1;
      }
    }
    .video {
      margin-top: 0.2rem;
      width: 100%;
    }
    .part1 {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-bottom: 0.4rem;
      .bg3 {
        width: 100%;
        position: absolute;
        top: -0.6rem;
        left: 0;
        z-index: -1;
      }
      .downLoadBtn {
        margin-top: 0;
      }
      .partTitle {
        width: 1.6rem;
        height: 0.4rem;
        border:1px solid #fff;
        border-radius:0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        margin: 0.32rem auto 0;
        img {
          height: 0.16rem;
          margin-left: 0.04rem;
        }
      }
      .box {
        position: relative;
        width: 3.5rem;
        margin: 0.36rem auto 0;
        padding: 0.08rem;
        border-radius: 0.08rem;
        border: 0.02rem solid rgba($color: #bedfec, $alpha: 0.5);
        background: radial-gradient(
            at top center,
            rgba(122, 189, 228, 0.8),
            rgba(76, 104, 149, 0.7)
          ),
          radial-gradient(
            50% 50% at top,
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0)
          );
        backdrop-filter: blur(5px);
        box-shadow: 0 0.14rem 0.3rem rgba($color: #7abde4, $alpha: 0.4);
        box-sizing: border-box;
        .light {
          position: absolute;
          width: 50%;
          height: 1rem;
          top: -2px;
          left: 50%;
          transform: translateX(-50%);
          border-top: 0.02rem solid;
          border-image: radial-gradient(
              at top center,
              rgba(228, 245, 252, 1),
              rgba(228, 245, 252, 0),
              rgba(228, 245, 252, 0)
            )
            2 2 2 2;
        }
        .boxTitle {
          width: 100%;
          text-align: center;
          font-family: Source Han Serif CN, Source Han Serif CN;
          font-weight: 600;
          font-size: 22px;
          color: #ffffff;
          margin-top: 0.08rem;
        }
        .boxContent {
          margin-top: 0.16rem;
          width: 100%;
          height: 0.82rem;
          border-radius: 0.08rem;
          border: 1px solid rgba(190, 220, 236, 1);
          background: linear-gradient(30deg, #f9fdff, #e2f0f6);
          box-shadow: 0 0.04rem 0.06rem rgba($color: #f9fdff, $alpha: 0.3);
          display: flex;
          flex-direction: row;
          align-items: center;
          .line {
            width: 1px;
            height: 0.3rem;
            background: rgba($color: #4c6895, $alpha: 0.2);
          }
          .boxCol {
            flex: 1;
            width: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            font-size: 13px;
            color: #4c6895;
            img {
              height: 0.2rem;
            }
          }
        }
      }
    }
    .part2 {
      position: relative;
      width: 100%;
      margin-top: 0.2rem;
      padding-bottom: 0.55rem;
      .bg4 {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
      .text_info{
        position: relative;
        width: 1.5rem;
        height: 0.44rem;
        margin:0 auto 16px;
        border-radius:0.08rem;
        transform: skewX(-12deg);
        background: linear-gradient(60deg,#F9FDFF,#E2F0F6);
        font-family: PingFang SC;
        font-size: 0.18rem;
        font-style: normal;
        font-weight: bold;
        color: #4C6895;
        text-align: center;
        line-height: 0.44rem;
        box-shadow: 0 4px 6px rgba($color: #BEDFEC, $alpha: 0.5);
        z-index: 2;
        &::after{
          content:'';
          position: absolute;
          bottom: -0.05rem;
          left: 50%;
          width: 0.1rem;
          height: 0.1rem;
          background: linear-gradient(60deg,#F9FDFF,#E2F0F6);
          transform:translateX(-50%) rotate(45deg);
          box-shadow: 0 4px 6px rgba($color: #BEDFEC, $alpha: 0.5);
          z-index: -1;
        }
      }
      .text_title{
        text-align: center;
        font-family: PingFang SC;
        font-size: 0.18rem;
        font-style: normal;
        font-weight: bold;
        color: #fff;
        z-index: 10;
        letter-spacing: 6px;
        margin-bottom: 0.2rem;
      }
      .downLoadBtn {
        width: 2.6rem;
        margin-top: 0;
      }
    }
  }
  .bg1 {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .bg2 {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .backBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img {
      width: 100%;
    }
  }
}
.lunbo {
  position: relative;
  .firstImg {
    position: absolute;
    top: 50%;
    left: -0.56rem;
    transform: translateY(-50%) scale(0.8);
  }
  .lastImg {
    position: absolute;
    top: 50%;
    right: -0.56rem;
    transform: translateY(-50%) scale(0.8);
  }
}
.swiper {
  margin-top: 0.2rem;
}
.swiper-slide {
  width: 1.62rem !important;
  text-align: center;
  transition: 300ms;
  transform: scale(0.8);
  img {
    width: 100%;
    border-radius: 0.08rem;
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  position: relative;
  img {
    border: 2px solid rgba($color: #bedfec, $alpha: 0.6);
    box-shadow: 0 0.06rem 0.2rem rgba($color: #7abde4, $alpha: 0.6);
  }
  &::before {
    content: "";
    position: absolute;
    width: 1rem;
    height: 0.4rem;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-top: 0.02rem solid;
    border-image: radial-gradient(
        at top center,
        rgba(228, 245, 252, 1),
        rgba(228, 245, 252, 0),
        rgba(228, 245, 252, 0)
      )
      2 2 2 2;
    z-index: 2;
  }
}
.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  span {
    width: 8px;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      width: 14px;
      background: #fff;
    }
  }
}
</style>
